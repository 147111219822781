import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';

import Image from '../images/patient-review-request.png';
import backgroundImage from '../images/prr-bg.png';

const StyledMostParentDiv = styled.div`
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: none;
    background-color: #fff;
  }
`;

const StyledImage = styled.img`
@media only screen and (max-width: 1440px) {
  margin-left: 5rem;
  width: 100%;
}
@media only screen and (max-width: 425px) {
  margin-left: 2rem;
  width: 100%;
}
`;

const PatientReviewRequest = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Patient Review Request'));
    dispatch(setParentTo('Patient Experience (PX)'));
    document.title = 'Patient Revie Request - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="patient-review-request-page">
      <TopNav />
      <Grid container display="flex" justifyContent="center" alignItems="center">
        <Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center" md={12} lg={12}>
          <StyledImage src={Image} />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default PatientReviewRequest;
