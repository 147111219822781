import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import Image from '../images/blog-content-packages.png';
import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  color: #fff;
  height: 860px;
  background-image: -webkit-linear-gradient(#002F5C,#000C19);
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
    background-image: url(${bubbles})
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  padding-right: 5rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    padding-right: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 750px;
    margin-top: 1vh;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    margin-top: 2rem;
  }
`;

const H1 = styled.h1`
  font-size: 45px;
  text-align: center;
  font-weight: 400;
  display: inline-block;
  margin-left: 6.5vw;
  margin-bottom: 10px;
  line-height: 1;
  margin: 0 auto .5em 1.3em;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3rem;
    margin-left: 2.5vw;
  }
  @media only screen and (max-width: 1024px) {
    color: #11406D;
    text-align: center;
    border-bottom: 7px solid #0F3D69;  
    padding-bottom: 1vh;
  }
  @media only screen and (max-width: 600px) {
    margin-left: 0;
    margin-top: 0;
  }
`;

const UnderlineBox = styled.div`
  width: 314px;
  height: 7px;
  background-color: #0F3D69;
  margin: 0 0 1.5em 3.8em;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const BlogContentPackages = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Blog Content Packages'));
    dispatch(setParentTo(''));
    document.title = 'Blog Content Packages - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="blog-content-packages">
      <TopNav />
      <Grid container display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap" alignContent="flex-start" rowSpacing={0} columnSpacing={0}>
        <H1>Blog Content Packages</H1>
        <UnderlineBox />
        <Grid sm={12} display="flex" justifyContent={{ md: 'center', lg: 'end' }} alignItems="flex-start">
          <StyledImage src={Image} alt="BLog Content Packages" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default BlogContentPackages;
