import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import Image from '../images/content-ignition-package.png';
import backgroundImage from '../images/cip-bg-image.jpg';
import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  color: #fff;
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: url(${bubbles})
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  margin-left: 3rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 750px;
    margin-top: 1vh;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    margin-top: 2rem;
  }
`;

const H1 = styled.h1`
  font-size: 45px;
  font-weight: 300;
  text-align: left;
  display: inline-block;
  margin-left: 6.5vw;
  margin-bottom: 10px;
  line-height: 1;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3rem;
    margin-left: 2.5vw;
  }
  @media only screen and (max-width: 1024px) {
    color: #11406D;
    text-align: center;
    border-bottom: 7px solid #0F3D69;  
    padding-bottom: 1vh;
  }
  @media only screen and (max-width: 600px) {
    margin-left: 0;
    margin-top: 0;
  }
  &:after {
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    width: 50%; /* Change this to whatever width you want. */
    padding-top: 1.75vh; /* This creates some space between the element and the border. */
    border-bottom: 7px solid #0F3D69;  /* This creates the border. Replace black with whatever color you want. */
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

const ContentIgnitionPackage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Content Ignition Package'));
    dispatch(setParentTo(''));
    document.title = 'Content Igniton Package - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="content-linking-strategy-page">
      <TopNav />
      <H1>Content Ignition Packages</H1>
      <Grid container display="flex" justifyContent="end" alignItems="center">
        <Grid display="flex" alignItems="center" justifyContent={{ md: 'center', lg: 'end' }} sm={12}>
          <StyledImage src={Image} alt="Content Linking Strategy" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default ContentIgnitionPackage;
