import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import PackageTierBreakdown from '../components/PackageTierBreakdown';
import backgroundImage from '../images/cls-bg-image.jpg';
import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 860px;
  background-image: url(${backgroundImage});
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1280px) {
    height: 100%;
    background-image: url(${bubbles})
  }
`;
const GoldPackage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Gold Package'));
    dispatch(setParentTo(''));
    document.title = 'Gold Package - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="gold-package-page">
      <TopNav />
      {/*
        TODO Task: Fix PackageTierBreakdown error message
        **(this is used in gold-package AND platinum-package)**
        when PackageTierBreakdown is removed, error message dissapears, find a way to
        remove this message when PackageTierBreakdown is added back in:
        Error message:
        react-dom.development.js:86 Warning: Received `true` for a non-boolean attribute
        `item`. If you want to write it to the DOM, pass a string instead: item="true"
        or item={value.toString()}.
      */}
      <PackageTierBreakdown
        packageTier="Gold"
        blurb="What is our Gold product made up of and what does it cost?"
        image="gold-package.png"
        componentsList={[
          'Silver',
          'Automated Reviews',
          '70 Blog CIP(1)',
          '24 Blog CIP(2)',
          'Extra Blog',
          'Additional Social Posts']}
        monthlyList={['$699', '$199', '$729', '$600', '$250', '$200']}
        monthlyTotal="$2,677"

      />
    </StyledMostParentDiv>
  );
};

export default GoldPackage;
