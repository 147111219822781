const topNavUrl = [
  '/home',
  '/why-upgrade',
  '/content-linking-strategy',
  '/base-product-blog-differences',
  '/social-content-strategy',
  '/content-ignition-package',
  '/what-is-content-ignition-package',
  '/how-do-i-use-it',
  '/how-many-do-i-get',
  '/blog-content-packages',
  '/total-blogs',
  '/patient-experience-px',
  '/dashboard-and-appointment-reminder',
  '/patient-review-request',
  '/expectations',
  '/expectations-silver-to-gold',
  '/expectations-gold-to-platinum',
  '/gold-package',
  '/gold-price-offer',
  '/platinum-package',
  '/platinum-price-offer',
  '/special-add-on',
];

export default topNavUrl;
