import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';

import Image from '../images/blog-differences.png';
import backgroundImage from '../images/cls-bg-image.jpg';
import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: url(${bubbles})
  }
`;

const StyledImage = styled.img`
  max-width: 815px;
  margin-left: 3rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 550px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 350px;
  }
`;

const H1 = styled.h1`
  font-size: 80px;
  font-weight: 300;
  text-align: left;
  max-width: 75%;
  line-height: 1;
  color: #002040;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3.5rem;
  }
  @media only screen and (max-width: 600px) {
    max-width: 80%;
    font-size: 2.5rem;
  }
`;

const Blurb = styled.p`
  font-size: 20px;
  line-height: 35px;
  max-width: 75%;
  letter-spacing: calc(-1rem * 0.001 * 20);
  color: #4B4949;
  margin: 1rem 0;
  @media only screen and (max-width: 320px) {
    line-height: 1.5;
  }
`;

const BaseProductBlog = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Base Product Blog Differences'));
    dispatch(setParentTo(''));
    document.title = 'Base Product Blog Differences - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="content-linking-strategy-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" alignItems="center" rowSpacing={0} columnSpacing={0}>
        <Grid style={{ paddingLeft: '0rem' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" md={12} lg={6}>
          <H1>Base Product Blog Differences</H1>
          <Blurb>
            Content is king. Upgrading your plan from Silver to Gold or Gold to Platinum means
            you&apos;ll receive more of what Google needs to grow your online footprint. Upgrading
            from Silver to Gold results in not only double the monthly blogs, but triple the monthly
            content and that is just one of the components that come with upgrading.

          </Blurb>
        </Grid>
        <Grid minHeight={400} md={12} lg={6} container display="flex" justifyContent="center" alignItems="start" flexDirection="row-reverse">
          <StyledImage src={Image} alt="Content Linking Strategy" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default BaseProductBlog;
