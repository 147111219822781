import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import PackageTierBreakdownDoubleCol from '../components/PackageTierBreakdownDoubleCol';
import Image from '../images/platinum-price-offer.png';
import backgroundImage from '../images/cls-bg-image.jpg';
import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: url(${bubbles})
  }
`;

const StyledImage = styled.img`
  max-width: 1100px;
  margin-left: 14rem;
    
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 750px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 350px;
  }
`;

const H1 = styled.h1`
  font-size: 68px;
  font-weight: 200;
  padding: 0 0 3rem 20px;
  color: #002040;
  line-height: 85px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  margin: 0px;
  @media only screen and (max-width: 1280px) {
    font-size: 65px;
  }

`;

const PlatinumPriceOffer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Platinum Price Offer'));
    dispatch(setParentTo('Platinum Package'));
    document.title = 'Platinum Price Offer - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="content-linking-strategy-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" alignItems="center" rowSpacing={0} columnSpacing={0}>
        <Grid style={{ zIndex: '1' }} display="flex" flexDirection="column" md={12} lg={7} sx={{ pl: { lg: 5 } }}>
          <H1>Platinum Package</H1>
          <PackageTierBreakdownDoubleCol
            breakedownTitleOne="Month to month option"
            priceListColOne={['$0', '$2,999']}
            breakedownTitleTwo="12 Installment Option"
            priceListColTwo={['$0', '$2,849', '$1,999', '$2,499', '$2,849']}
          />
        </Grid>
        <Grid minHeight={400} md={12} lg={5} container display="flex" justifyContent="center" alignItems="center" flexDirection="row-reverse">
          <StyledImage src={Image} alt="Content Linking Strategy" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default PlatinumPriceOffer;
