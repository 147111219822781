import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
// import TopNav from './TopNav';
import SideNav from './SideNav';
// this is where you will import pages based on name props

const LayoutContainer = styled.div`
  display: flex;
  max-height: 900px;
  max-width: 1920px;
  overflow-y: hidden;
  overflow-x: hidden;
  //letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1024px) {
    overflow-y: auto;
  }
`;

const StyledMenuItem = styled.div`
  // This may get removed later on to eliminate whitespace
  margin-top: 2.5rem;
  width: 312px;
  padding: 10px .5vw;
  background-color: #11406D;
  color: white;
  max-height: 900px;
  border-radius: 25px 0 0 25px;
  z-index: 1;
  @media only screen and (max-width: 1024px) {
    display: none;
    margin-top: 0;
    border-radius: 0;
  }
`;

const StyledMainContainer = styled.div`
  // This may get removed later on to eliminate whitespace
  margin-top: 2.5rem;
  padding: 0;
  width:-webkit-fill-available;
  height: 900px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  @media only screen and (max-width: 1024px) {
    margin-top: 0;
    overflow-y: auto;
  }
`;

const Layout = () => (
  <LayoutContainer>
    <StyledMenuItem>
      <SideNav />
    </StyledMenuItem>
    <StyledMainContainer>
      {/* <TopNav /> */}
      <Outlet />
    </StyledMainContainer>
  </LayoutContainer>
);

export default Layout;
