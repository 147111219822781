/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import Image from '../images/px-page.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 860px;
  background-color: #002850;
  background-image: -webkit-linear-gradient(-30deg, #003263 60%, #1183F2 50%);
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
  }
`;

const Img = styled.img`
  max-width: 900px;
  box-shadow: 0 1px 1px -4px lightblue;
    justify-content: flex-end;
    overflow-x: clip;
    margin-left: 100px;
    // width: 100%;
    @media only screen and (max-width: 1650px) {
      max-width: 700px;
    }
  @media only screen and (max-width: 1440px) {
    width: 100%;
  }
  @media only screen and (max-width: 1280px) {
    margin-left: 250px;
    width: 650px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 600px;
    margin-left: 0px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 425px) {
    max-width: 400px;
  }
`;

const H1 = styled.h1`
  font-size: 70px;
  font-weight: 300;
  line-height: 85px;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3.5rem;
  }
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 1;
  }
`;

const Sup = styled.sup`
  font-size: 14px;
  font-weight: 600;
  background-color: #0DCB38;
  border-radius: 50%;
  padding: 5px;
  margin-left: 5px;
  `;

const PxBlurb = styled.p`
  font-size: 22px;
  line-height: 35px;
  width: 554px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1280px) {
    width: 400px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 15px;
    width: 400px;
  }
`;
const PatientExperiencePx = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Patient Experience (PX)'));
    dispatch(setParentTo(''));
    document.title = 'Patient Experience - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="PatientExperiencePx-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" rowSpacing={0} columnSpacing={0}>
        <Grid display="flex" flexDirection="column" justifyContent="center" lg={6}>
          <H1>
            Patient Experience
            <Sup>PX</Sup>
          </H1>
          <PxBlurb>
            Our review request solution is an intuitive and streamlined experience for your team to gather feedback from your patients. This solution asks patients for feedback shortly after an appointment, empowering your practice to learn and improve over time.
          </PxBlurb>
        </Grid>
        <Grid minHeight={400} md={12} lg={5} container display="flex" alignItems="center" sx={{ justifyContent: { md: 'center' } }}>
          <Img src={Image} alt="icon" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default PatientExperiencePx;
