import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';

const MainGridContainer = styled(Grid)`
display:grid;
grid-template-columns: fit-content(300px) fit-content(200px) fit-content(300px) 1fr;
@media only screen and (max-width: 600px) {
  grid-template-columns: repeat(1 , 1fr);

`;

const Breakdown = styled(Box)`
  font-size: 20px;
  background-color: #002E5C;
  color: #BFBFBF;
  width: 100%;
  line-height: 30px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ParentContentBoxOne = styled(Box)`
display:grid;
grid-template-rows: repeat(8 , 1fr);
padding: 0 15px;
`;

const ParentContentBoxTwo = styled(Box)`
display:grid;
grid-template-rows: repeat(8 , 1fr);
padding: 0 15px;
min-width: 270px;
`;

const PriceContentBox = styled(Box)`
display: flex;
color:#002040;
align-items:center;
padding: 0 5px;
height: 80px;
@media only screen and (max-width: 600px) {
height: 60px;
}

`;

// const VerticalLineTop = styled.div`
// border-left: 2px solid #002E5C;
// height: 255px;
// `;

// const VerticalLineBottom = styled.div`
// border-left: 2px solid #002E5C;
// height: 255px;
// `;

const Price = styled.h3`
padding-right: 10px;
font-size: 35px;
font-weight: 500;
font-weight: bold;
`;

const PackageTierBreakdownDoubleCol = ({ ...props }) => (
  <MainGridContainer>
    <ParentContentBoxOne>
      <Breakdown>{props.breakedownTitleOne}</Breakdown>
      <PriceContentBox>
        <Price>{props.priceListColOne[0]}</Price>
        <p>content and web development</p>
      </PriceContentBox>
      <PriceContentBox>
        <Price>{props.priceListColOne[1]}</Price>
        <p>month to month</p>
      </PriceContentBox>
      {/* <PriceContentBox>Tiered billing for campaign ramp up:</PriceContentBox> */}
      {/* <PriceContentBox style={{ gridRowStart: '6', gridRowEnd: '8', textAlign: 'center' }}>
        <h2>
          Not available on a
          month to month
        </h2>
      </PriceContentBox> */}
    </ParentContentBoxOne>
    {/* <Grid style={{ padding: '0 1rem' }}>
      <VerticalLineTop />
      <p style={{ marginLeft: '-6px', fontWeight: 'bold' }}>OR</p>
      <VerticalLineBottom />
    </Grid> */}
    <ParentContentBoxTwo>
      <Breakdown>{props.breakedownTitleTwo}</Breakdown>
      <PriceContentBox>
        <Price>{props.priceListColTwo[0]}</Price>
        <p>content and web development</p>
      </PriceContentBox>
      <PriceContentBox>
        <Price>{props.priceListColTwo[1]}</Price>
        <p>month to month</p>
      </PriceContentBox>
      {/* <PriceContentBox>Tiered billing for campaign ramp up:</PriceContentBox> */}
      {/* <PriceContentBox>
        <Price>{props.priceListColTwo[2]}</Price>
        <p>1st month</p>
      </PriceContentBox>
      <PriceContentBox>
        <Price>{props.priceListColTwo[3]}</Price>
        <p>2nd month</p>
      </PriceContentBox>
      <PriceContentBox>
        <Price>{props.priceListColTwo[4]}</Price>
        <p>3rd month</p>
      </PriceContentBox> */}
    </ParentContentBoxTwo>
  </MainGridContainer>

);

export default PackageTierBreakdownDoubleCol;
