import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { setPageTo, setParentTo } from '../slices/setCurrentPage';

import TopNav from '../components/TopNav';
import Image from '../images/advanced-solutions.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 860px;
  background-color: #002850;
  background-image: -webkit-linear-gradient(-30deg, #003263 60%, #1183F2 50%);
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
  }
`;

const H1 = styled.h1`
  font-size: 5.5rem;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3.5rem;
  }
`;

const SubHeader = styled.h2`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  margin: 10px 0 20px 0;
  color: #A1A1A1;
  @media only screen and (max-width: 1440px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 425px) {
    color: #fff;
    margin: 1.5rem 0;
  }
`;

const StyledSpan = styled.span`
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  padding: ${(props) => (props.padding !== '' ? props.padding : '1rem')};
  border-right: ${(props) => (props.hasborder ? '2px solid #fff' : 'none')};
  letter-spacing: calc(1rem * 0.001 * 300);
  @media only screen and (max-width: 425px) {
    font-size: 11px;
  }
`;

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTo('Home'));
    dispatch(setParentTo(''));
    document.title = 'Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="home-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" alignItems="center" rowSpacing={0} columnSpacing={0}>
        <Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center" md={12} lg={6}>
          <H1>Advanced Solutions</H1>
          <SubHeader>The catalyst to new patient call growth</SubHeader>
          <Grid display="flex" container>
            <StyledSpan padding="0 1.5rem 0 0" hasborder="true">Strategy</StyledSpan>
            <StyledSpan padding="0 1.5rem 0 1.5rem" hasborder="true">Planning</StyledSpan>
            <StyledSpan padding="0 0 0 1.5rem">Results</StyledSpan>
          </Grid>
        </Grid>
        <Grid minHeight={400} md={12} lg={5} container display="flex" justifyContent="center" alignItems="start">
          <img src={Image} style={{ maxWidth: '525px' }} alt="icon" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default Home;
