// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../slices/counterSlice';
import setCurrentPage from '../slices/setCurrentPage';

export default configureStore({
  reducer: {
    counter: counterReducer,
    currentPage: setCurrentPage,
  },
});
