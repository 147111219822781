import React, { useEffect } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import Image from '../images/expectations.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 860px;
  background-color: #002850;
  background-image: -webkit-linear-gradient(-30deg, #032E53, #002040 60%, #1183F2, #0459A4 50%);
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
  }
  @media only screen and (max-width: 600px) {
    div > div {
      padding: 1.5rem !important;
      align-items: flex-start;
    }
  }
`;

const StyledImage = styled.img`
  max-width: 925px;
  margin-left: 3rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 750px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 350px;
  }
`;

const H1 = styled.h1`
  font-size: 33px;
  font-weight: 500;
  line-height: 1;
  color: #FFFFFF;
  padding-left: 5px;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 2rem;
  }
`;

const PageTitle = styled.h2`
  font-size: 83px;
  font-weight: 300;
  text-align: center;
  line-height: 1;
  letter-spacing: calc(-1rem * 0.001 * 20);
  margin: 20px 0;
  color: #FFFFFF;
  @media only screen and (max-width: 1440px) {
    font-size: 4rem;
    margin: 20px 0 5px 0;
  }
  @media only screen and (max-width: 425px) {
    margin: 1rem 0 .5rem 0;
  }
`;

const MainTextContent = styled.h4`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: calc(-1rem * 0.001 * 20);
  line-height: 2rem;
  color: #FFFFFF;
  margin: .5rem 0 0 0;
`;

const SideTextContent = styled.p`
  font-size: 11px;
  font-weight: 600;
  letter-spacing: calc(-1rem * 0.001 * 20);
  color: #FFFFFF;
  margin: 0;
`;

const Expectations = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Expectations'));
    dispatch(setParentTo(''));
    document.title = 'Expectations - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="home-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" alignItems="center" rowSpacing={0} columnSpacing={0}>
        <Grid md={12} lg={6} style={{ paddingLeft: '4rem' }} display="flex" flexDirection="column" alignItems="start">
          <H1>We are here for results</H1>
          <PageTitle>Expectations</PageTitle>
          <MainTextContent>
            Our campaign expectations are based upon over 300 upgraded campaigns
            and 7,000 performance reports. At Doctor Genius, we strive to give the most
            accurate expectations as possible and that starts with reviewing
            past results to discuss future expectations.*
          </MainTextContent>
        &nbsp;
          <SideTextContent>
            *Your market, competition, and demographics play a role in what is delivered.
            {/* eslint-disable-next-line max-len */}
            However, the ranges we will review have proven to be accurate for over 90% of our upgraded
            campaigns
          </SideTextContent>
        </Grid>
        <Grid minHeight={700} md={12} lg={6} container display="flex" justifyContent="center" alignItems="center" flexDirection="row-reverse">
          <StyledImage src={Image} alt="Content Linking Strategy" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default Expectations;
