import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { setPageTo, setParentTo } from '../slices/setCurrentPage';

import backgroundImage from '../images/cls-bg-image.jpg';
import bubbles from '../images/bubbles-bg.png'; import TopNav from '../components/TopNav';
import Image from '../images/what-is-cip.png';
import listStyle from '../images/styled-list-item.png';

const StyledMostParentDiv = styled.div`
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1440px) {
    padding-left: 2rem;
  }
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: url(${bubbles})
  }
`;
const StyledImage = styled.img`
  max-width: 815px;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 300px;
  }
`;
const Header = styled.h1`
  color: #002040;
  font-size: 4rem;
  font-weight: 300;
  text-align: left;
  width: 554px;
  line-height: 1;
  letter-spacing: calc(-1rem * 0.001 * 20);
  margin: 0;
  @media only screen and (max-width: 1280px) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
const SubHeader = styled.p`
  color: #000000;
  font-size: 1.75rem;
  line-height: 37px;
  text-align: center;
  line-height: 37px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  width: 554px;
  text-align: left;
  padding-bottom: 2rem;
  border-bottom: solid 1px;
  margin-right: 1rem;
  @media only screen and (max-width: 1280px) {
    width: 484px;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    width: 992px;
  }
  @media only screen and (max-width: 1024px) {
    width: 736px;
  }
  @media only screen and (max-width: 480px) {
    width: 393px;
  }
`;
const List = styled.ul`
  list-style-image: url(${listStyle});
`;
const ListItem = styled.li`
  color: #000000;
  font-size: 1.875rem;
  line-height: 35px;
  font-weight: 400;
  width: 560px;
  padding: 2rem 0 0 1rem;
  @media only screen and (max-width: 1280px) {
    width: 484px;
    font-size: 1rem;
    padding: 1rem 0 0 1rem;
  }
  @media only screen and (max-width: 1024px) {
    width: 992px;
  }
  @media only screen and (max-width: 1024px) {
    width: 736px;
  }
  @media only screen and (max-width: 480px) {
    width: 300px;
  }
`;

const WhatIsContentIgnitionPackage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo("What's a Content Ignition Package?"));
    dispatch(setParentTo('Content Ignition Package'));
    document.title = 'What is a Content Ignition Package? - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="what-is-cip-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" alignItems="center" rowSpacing={0} columnSpacing={0}>
        <Grid style={{ paddingLeft: '0rem' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" md={12} lg={6}>
          <Header>What&apos;s a Content Ignition Package?</Header>
          <SubHeader>A content ignition package is the catalyst to your growth online!</SubHeader>
          <List>
            <ListItem>Each 24-blog CIP adds 12,000 SEO rich words to your site</ListItem>
            {/* eslint-disable-next-line max-len */}
            <ListItem>120 additional internal links for Google to recognize (and trust your site)</ListItem>
            <ListItem>This helps your site receive freshness updates 1 - 2x per week</ListItem>
          </List>
        </Grid>
        <Grid minHeight={250} md={12} lg={6} container display="flex" justifyContent="center" alignItems="center" flexDirection="row-reverse">
          <StyledImage src={Image} alt="Content Linking Strategy" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default WhatIsContentIgnitionPackage;
