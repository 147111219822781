/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import TopNav from '../components/TopNav';
import Image from '../images/why-upgrade.png';

const StyledMostParentDiv = styled.div`
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-color: #002850;
  background-image: -webkit-linear-gradient(-30deg, #003263 60%, #1183F2 50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
  }
`;

const H1 = styled.h1`
  font-size: 60px;
  font-weight: 400;
  width: 905px;
  line-height: 80px;
  margin: 2rem 0 0 0;
  color: #fff;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1024px) {
    margin: 0;
    font-size: 40px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 45px;
  }
  @media only screen and (max-width: 425px) {
    width: 350px;
    line-height: 40px;
    font-size: 2rem;
  }
`;

const SubHeader = styled.h2`
  font-size: 45px;
  font-weight: 300;
  font-style: italic;
  margin: 1rem 0 0 0;
  color: #E8E8E8;
  @media only screen and (max-width: 1440px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    margin: 0;
  }
`;

const Ol = styled.ol`
  list-style: none;
  counter-reset: item;
  padding: 4rem 0 0 0;
  width: 750px;
  height: 323px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1280px) {
    width: 700px;
  }  
  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    margin: 0;
    padding: 2rem 0 0 0;
    width: 905px;
  }
  @media only screen and (max-width: 768px) {
    line-height: 40px;
  }
  @media only screen and (max-width: 425px) {
    width: 350px;
    line-height: 40px;
    font-size: 2rem;
    padding: 1rem 0 0 0;
  }
`;

const ListItem = styled.li`
  font-size: 27px;
  font-weight: 300;
  margin: 1rem 0 2.5rem 0;
  color: #ffffff;
  counter-increment: item;
  &:before {
    font-size: 18px;
   content: counter(item);
   background: #0B6FCC;
   border-radius: 100%;
   color: white;
   width: 1.5em;
   text-align: center;
   display: inline-block;
  }
  @media only screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 425px) {
    margin: 1rem 0 1rem 0;
    justify-content: center;
  }
`;

const Img = styled.img`
  max-width: 1200px;
  margin-left: 3rem;
  padding-bottom: 3rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 450px;
    margin-left: 3vw;
  }
  @media only screen and (max-width: 1280px) {
    margin-left: 6vw;
  }
`;

const WhyUpgrade = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTo('Why Upgrade?'));
    dispatch(setParentTo(''));
    document.title = 'Why Upgrade? - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);

  return (
    <StyledMostParentDiv className="why-upgrade">
      <TopNav />
      <Grid container display="flex" style={{ height: '100%' }} justifyContent="center">
        <Grid display="flex" flexDirection="column" md={12} lg={6} sx={{ pl: 5 }}>
          <H1>Who should consider upgrading?</H1>
          <SubHeader>Have you asked yourself...</SubHeader>
          <Ol>
            <ListItem><span style={{ paddingLeft: '15px' }}>Am I doing enough to beat my competitors?</span></ListItem>
            <ListItem><span style={{ paddingLeft: '15px' }}>I am seeing growth but I want faster growth? More growth?</span></ListItem>
            <ListItem><span style={{ paddingLeft: '15px' }}>Why am I not getting more of a particular type of patient?</span></ListItem>
            <ListItem><span style={{ paddingLeft: '15px' }}>Do I have the volume to keep my new doctors happy?</span></ListItem>
          </Ol>
        </Grid>
        <Grid minHeight={450} md={12} lg={6} display="flex" container justifyContent="center" alignItems="center">
          <Img src={Image} alt="icon" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default WhyUpgrade;
