/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import BlueArrow from '../images/blue-arrow.png';
import Image from '../images/logo-white.png';

// data
import sideNavUrl from '../context/sideNavUrl';

const StandardParentLink = styled(Link)`
  display: flex;
  font-size: 15px;
  padding: 0 .75rem;
  color: white;
  text-decoration: none;
  letter-spacing: calc(-1rem * 0.001 * 20);
`;

const StandardChildLink = styled(Link)`
  font-size: 12px;
  color: white;
  text-decoration: none;
  letter-spacing: calc(-1rem * 0.001 * 20);
  padding-left: .25vw;
`;

const BlueOverlayLink = styled(Link)`
  display: flex;
  border-radius: 25px;
  padding:  .1rem .75rem;
  font-size: 15px;
  color: white;
  text-decoration: none;
  background-color: #234C71;
  letter-spacing: calc(-1rem * 0.001 * 20);
`;

// Function to handle the output of Multi or Single Level Menu Item
const EachLink = ({ item }) => {
  const currentpage = useSelector((state) => state.currentPage.value);
  const parentpage = useSelector((state) => state.currentPage.parent);
  const [open, setOpen] = useState(false);
  const dropdownItems = item.items;

  const handleExpandClick = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  if (dropdownItems) { // ? check for dropdown menu item : display zero-dropdown component
    const { items: children } = item;
    let parentPageExists;
    // eslint-disable-next-line no-unused-expressions
    parentpage !== '' ? parentPageExists = true : parentPageExists = false;
    return ( // display multi-level dropdown menu item
      <>
        <ListItem>
          {currentpage === item.title || parentpage === item.title
            ? (
              <BlueOverlayLink to={item.path}>
                <ListItemText onClick={() => { setOpen((prev) => !prev); }} style={{ fontFamily: 'Roboto, sans-serif' }} disableTypography primary={item.title} />
                {open || parentPageExists ? <ExpandLessIcon onClick={(e) => { handleExpandClick(e); }} id="expandIcon" /> : <ExpandMoreIcon onClick={(e) => { handleExpandClick(e); }} id="expandIcon" />}
              </BlueOverlayLink>
            )
            : (
              <StandardParentLink to={item.path}>
                <ListItemText onClick={() => { setOpen((prev) => !prev); }} style={{ fontFamily: 'Roboto, sans-serif' }} disableTypography primary={item.title} />
                {open ? <ExpandLessIcon onClick={(e) => { handleExpandClick(e); }} id="expandIcon" /> : <ExpandMoreIcon onClick={(e) => { handleExpandClick(e); }} id="expandIcon" />}
              </StandardParentLink>
            )}
        </ListItem>
        {children.map((child, key) => {
          let newFlag; // Flag to compare parentpage to sideNavUrl's parent page to determine whether or not that the appropriate dropdown should be triggered
          // eslint-disable-next-line no-unused-expressions
          child.parent === parentpage ? newFlag = true : newFlag = false;
          return (
            <Collapse in={newFlag || open} timeout="auto" key={key}>
              <List>
                <ListItem style={{ display: 'flex', justifyContent: 'start', padding: '0vw 0vw 0vw 2vw' }}>
                  {currentpage === child.title ? <div><img alt="blue-arrow" src={BlueArrow} /></div> : null}
                  <StandardChildLink to={child.path}>
                    <ListItemText style={{ fontFamily: 'Open Sans, sans-serif' }} disableTypography primary={child.title} />
                  </StandardChildLink>
                </ListItem>
              </List>
            </Collapse>
          );
        })}
      </>
    );
  }
  // display single-level non-dropdown menu item
  return (
    <ListItem>
      {currentpage === item.title
        ? (
          <BlueOverlayLink to={item.path}>
            <ListItemText style={{ fontFamily: 'Open Sans, sans-serif' }} disableTypography primary={item.title} />
          </BlueOverlayLink>
        )
        : (
          <StandardParentLink to={item.path}>
            <ListItemText style={{ fontFamily: 'Open Sans, sans-serif' }} disableTypography primary={item.title} />
          </StandardParentLink>
        )}
    </ListItem>
  );
};

const SideNav = () => (
  <div>
    <img style={{ width: '205px', margin: '1em' }} src={Image} alt="icon" />
    {sideNavUrl.map((item, key) => (
      <div key={key} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <EachLink item={item} />
      </div>
    ))}
  </div>
);

export default SideNav;
