import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Image from '../images/package-page.png';

const H1 = styled.h1`
  font-size: 68px;
  font-weight: 200;
  color: #002040;
  line-height: 85px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  margin: 0px;
  @media only screen and (max-width: 1280px) {
    font-size: 65px;
  }

`;
const Blurb = styled.p`
  font-size: 39px;
  color: #0F3D69;
  font-weight: 200;
  line-height:45px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  width:658px;
  margin: 0px;
`;
const Breakdown = styled.p`
  font-size: 26px;
  background-color: #002E5C;
  border-radius: 10px;
  color: #BFBFBF;
  width: 657px;
  line-height: 30px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const ColName = styled.p`
  font-size: 20px;
  color: #0E3D68;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;
`;
const Totals = styled.p`
  font-size: 26px;
  color: #0E3D68;
  font-weight: 600;
  text-align: center;
  line-height:30px;
`;
const ComponentList = styled.li`
  font-size: 26px;
  color: #303030;
  font-weight: 200;
  line-height: 45px;
  list-style-type: none;
  text-align: left;
  margin-left: 100px;
  letter-spacing: calc(-1rem * 0.001 * 20);
  width: 300px;
`;
const ListItem = styled.li`
  font-size: 26px;
  color: #0E3D68;
  font-weight: 600;
  text-align: left;
  line-height: 45px;
  list-style-type: none;
  letter-spacing: calc(-1rem * 0.001 * 20);
  margin-left: 110px;
`;
const Img = styled.img`
  width: 1200px;
  box-shadow: 0 1px 1px -4px lightblue;
  // margin: 0 0 4rem 6rem;
  @media only screen and (max-width: 1440px) {
    margin-left: 2rem;
  }
  @media only screen and (max-width: 1280px) {
    display: none;
  }
`;

const PackageTierBreakdown = ({ ...props }) => (
  <Grid container display="flex" style={{ height: '100%' }} justifyContent="center" sx={{ pl: { lg: 5 } }}>
    <Grid display="flex" flexDirection="column" justifyContent="center" md={12} lg={5}>
      <H1>{` ${props.packageTier} Package`}</H1>
      <Blurb>{ props.blurb }</Blurb>
      <Breakdown>{`${props.packageTier} Breakdown`}</Breakdown>
      <Box sx={{ flexGrow: 1 }} alignItems="center">
        <Grid container spacing={2} sx={{ borderBottom: 1 }} style={{ width: '657px', borderBottomColor: '#B7B8BA' }}>
          <Grid item xs={6}>
            <ColName className="grid-elements">Components</ColName>
          </Grid>
          <Grid item xs={6}>
            <ColName className="grid-elements">Monthly</ColName>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ borderBottom: 1 }} style={{ width: '657px', borderBottomColor: '#B7B8BA' }}>
          <Grid item xs={6}>
            {props.componentsList.map((listItem) => (
              <ComponentList key={listItem}>
                {listItem}
              </ComponentList>
            ))}
          </Grid>
          <Grid item xs={6}>
            {props.monthlyList.map((listItem) => (
              <ListItem key={listItem}>
                {listItem}
              </ListItem>
            ))}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ width: '657px' }}>
          <Grid item xs={5}>
            <Totals className="grid-elements">Total</Totals>
          </Grid>
          <Grid item xs={7}>
            <Totals className="grid-elements" style={{ marginLeft: '50px' }}>{ props.monthlyTotal }</Totals>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    <Grid display="flex" flexDirection="column" justifyContent="start" md={12} lg={7}>
      <Img src={Image} display="flex" />
    </Grid>
  </Grid>

);

export default PackageTierBreakdown;
