const sideNavUrl = [
  {
    title: 'Home',
    path: '/home',
  },
  {
    title: 'Why Upgrade?',
    path: '/why-upgrade',
  },
  {
    title: 'Content & Linking Strategy',
    path: '/content-linking-strategy',
  },
  {
    title: 'Base Product Blog Differences',
    path: '/base-product-blog-differences',
  },
  {
    title: 'Social Content Strategy',
    path: '/social-content-strategy',
  },
  {
    title: 'Content Ignition Package',
    path: '/content-ignition-package',
    items: [
      {
        title: 'What\'s a Content Ignition Package?',
        path: '/what-is-content-ignition-package',
        parent: 'Content Ignition Package',
      },
      {
        title: 'How do I use it?',
        path: '/how-do-i-use-it',
        parent: 'Content Ignition Package',
      },
      {
        title: 'How many do I get?',
        path: '/how-many-do-i-get',
        parent: 'Content Ignition Package',
      },
    ],
  },
  {
    title: 'Blog Content Packages',
    path: '/blog-content-packages',
    items: [
      {
        title: 'Total Blogs',
        path: '/total-blogs',
        parent: 'Blog Content Packages',
      },
    ],
  },
  {
    title: 'Patient Experience (PX)',
    path: '/patient-experience-px',
    items: [
      {
        title: 'Dashboard & Appointment Reminder',
        path: '/dashboard-and-appointment-reminder',
        parent: 'Patient Experience (PX)',
      },
      {
        title: 'Patient Review Request',
        path: '/patient-review-request',
        parent: 'Patient Experience (PX)',
      },
    ],
  },
  {
    title: 'Expectations',
    path: '/expectations',
    items: [
      {
        title: 'Expectation: Silver to Gold',
        path: '/expectations-silver-to-gold',
        parent: 'Expectations',
      },
      {
        title: 'Expectation: Gold to Platinum',
        path: '/expectations-gold-to-platinum',
        parent: 'Expectations',
      },
    ],
  },
  {
    title: 'Gold Package',
    path: '/gold-package',
    items: [
      {
        title: 'Gold Price Offer',
        path: '/gold-price-offer',
        parent: 'Gold Package',
      },
    ],
  },
  {
    title: 'Platinum Package',
    path: '/platinum-package',
    items: [
      {
        title: 'Platinum Price Offer',
        path: '/platinum-price-offer',
        parent: 'Platinum Package',
      },
    ],
  },
  {
    title: 'Special Add-On',
    path: '/special-add-on',
  },

];

export default sideNavUrl;
