import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import CardOne from '../images/social-content-silver-package.png';
import CardTwo from '../images/social-content-gold-package.png';
import CardThree from '../images/social-content-platinum-package.png';
import backgroundImage from '../images/scs-bg-image.jpg';
// import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: none;
    background-color: #fff;
  }
`;

const StyledImage = styled.img`
  max-width: 815px;
  margin-left: 3rem;
  margin-top: 5vh;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 550px;
    margin-top: 1vh;
  }
  @media only screen and (max-width: 600px) {
    max-width: 350px;
  }
`;

const H1 = styled.h1`
  font-size: 69px;
  font-weight: 300;
  text-align: center;
  line-height: 1;
  color: #fff;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3.5rem;
  }
  @media only screen and (max-width: 1024px){
    color: black;
  }
  @media only screen and (max-width: 600px) {
    max-width: 80%;
    font-size: 2.5rem;
  }
`;

const Blurb = styled.p`
  font-size: 20px;
  line-height: 35px;
  max-width: 70%;
  text-align: center;
  letter-spacing: calc(-1rem * 0.001 * 20);
  color: #fff;
  margin: 1rem 0;
  @media only screen and (max-width: 1024px){
    color: black;
  }
  @media only screen and (max-width: 600px){
    text-align: left;
  }
  @media only screen and (max-width: 320px) {
    line-height: 1.5;
  }
`;

const SocialContentStrategy = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Social Content Strategy'));
    dispatch(setParentTo(''));
    document.title = 'Social Content Strategy - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="content-linking-strategy-page">
      <TopNav />
      <Grid container display="flex" justifyContent="center" alignItems="center">
        <Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center" md={12} lg={12}>
          <H1>Social Content Strategy</H1>
          <Blurb>
            Backlinks and social activity (social citations) are also very important for overall
            online visibility. Put plainly, having active social channels shows Google that you
            are a real local business doing real work. This builds your credibility online with
            search engines.
          </Blurb>
        </Grid>
      </Grid>
      <Grid container sm={12} columnSpacing={{ lg: 0, xl: 6 }} display="flex" justifyContent="center" alignItems="center">
        <Grid md={4} lg={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <StyledImage src={CardOne} alt="Social Content Strategy - Silver Package" />
        </Grid>
        <Grid md={4} lg={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <StyledImage src={CardTwo} alt="Social Content Strategy - Gold Package" />
        </Grid>
        <Grid md={4} lg={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <StyledImage src={CardThree} alt="Social Content Strategy - Platinum Package" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default SocialContentStrategy;
