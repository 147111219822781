import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import Image from '../images/dashboard-and-appointment-reminder.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 663px;
  background-color: white;
  background-image: -webkit-linear-gradient(-90deg, #0D58A4 25%, #001D3A 90%);
  border-radius: 0 25px 0 0;
  font-family: Roboto, sans-serif;
`;

const Img = styled.img`
  max-width: 100%;
  padding: 0rem 0rem 0rem 5rem;
  box-shadow: 0 1px 1px -4px lightblue;
`;
const DashboardAndAppointmentReminder = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Dashboard & Appointment Reminder'));
    dispatch(setParentTo('Patient Experience (PX)'));
    document.title = 'Dashboard & Appointment Reminder - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv>
      <TopNav />
      <Grid container display="flex" style={{ height: '100%' }} justifyContent="center" alignItems="center">
        <Img src={Image} alt="icon" />
      </Grid>
    </StyledMostParentDiv>
  );
};

export default DashboardAndAppointmentReminder;
