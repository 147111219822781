import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';

import Image from '../images/content-linking-strategy.png';
import backgroundImage from '../images/cls-bg-image.jpg';
import bubbles from '../images/bubbles-bg.png';

const StyledMostParentDiv = styled.div`
  //removing fixed-height changed border-radius in bottom-right DOM
  height: 860px;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    background-image: url(${bubbles})
  }
`;

const StyledImage = styled.img`
  max-width: 1025px;
  margin-left: 3rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 750px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 350px;
  }
`;

const H1 = styled.h1`
  font-size: 33px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  color: #0F3D69;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1440px) {
    font-size: 3rem;
  }
`;

const Question = styled.h2`
  font-size: 83px;
  font-weight: 300;
  text-align: center;
  line-height: 1;
  letter-spacing: calc(-1rem * 0.001 * 20);
  margin: 20px 0;
  color: #001F3D;
  @media only screen and (max-width: 1440px) {
    font-size: 1.75rem;
    margin: 20px 0 5px 0;
  }
  @media only screen and (max-width: 425px) {
    margin: 1rem 0 .5rem 0;
  }
`;

const Answer = styled.h4`
  font-size: 34px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  letter-spacing: calc(-1rem * 0.001 * 20);
  color: #063259;
  margin: .5rem 0 0 0;
`;

const Author = styled.p`
  font-size: 1em;
  font-weight: 600;
  letter-spacing: calc(-1rem * 0.001 * 20);
  color: #5A5A5A;
  margin: 1rem 0 .25rem 0;
`;

const Credentials = styled.p`
  font-size: 11px;
  font-weight: 600;
  letter-spacing: calc(-1rem * 0.001 * 20);
  color: #5A5A5A;
  margin: 0;
`;

const ContentLinkingStrategy = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Content & Linking Strategy'));
    dispatch(setParentTo(''));
    document.title = 'Content Linking Strategy - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="content-linking-strategy-page">
      <TopNav />
      <Grid minHeight={750} container display="flex" justifyContent="center" alignItems="center" rowSpacing={0} columnSpacing={0}>
        <Grid style={{ paddingLeft: '0rem' }} display="flex" flexDirection="column" justifyContent="center" alignItems="center" md={12} lg={6}>
          <H1>Content & Linking Strategy</H1>
          <Question>What are the top ranking factors?</Question>
          <Answer>&ldquo;The two factors are links and content&rdquo;</Answer>
          <Author>Andrey Lipattsev</Author>
          <Credentials>(Search Quality Senior Strategist at Google)</Credentials>
        </Grid>
        <Grid minHeight={400} md={12} lg={6} container display="flex" justifyContent="center" alignItems="center" flexDirection="row-reverse">
          <StyledImage src={Image} alt="Content Linking Strategy" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default ContentLinkingStrategy;
