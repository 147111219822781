/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
// eslint-disable-next-line no-unused-vars
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

const MyStockChart = ({ ...props }) => {
  let baseSilverGold; let ranges; let baseGoldPlatinum;
  let averages;
  if (props.pageName === 'silverToGold') {
    baseSilverGold = props.userInput;
    ranges = [
      [1, Math.round(baseSilverGold * (1 - 0.13)), Math.round(baseSilverGold * (1 + 1.00))],
      [2, Math.round(baseSilverGold * (1 - 0.25)), Math.round(baseSilverGold * (1 + 1.27))],
      [3, Math.round(baseSilverGold * (1 - 0.13)), Math.round(baseSilverGold * (1 + 1.92))],
      [4, Math.round(baseSilverGold * (1 - 0.13)), Math.round(baseSilverGold * (1 + 1.92))],
      [5, Math.round(baseSilverGold * (1 - 0.14)), Math.round(baseSilverGold * (1 + 2.12))],
      [6, Math.round(baseSilverGold * (1 + 0.02)), Math.round(baseSilverGold * (1 + 2.73))],
      [7, Math.round(baseSilverGold * (1 - 0.05)), Math.round(baseSilverGold * (1 + 2.83))],
      [8, Math.round(baseSilverGold * (1 + 0.02)), Math.round(baseSilverGold * (1 + 2.91))],
      [9, Math.round(baseSilverGold * (1 + 0.06)), Math.round(baseSilverGold * (1 + 3.80))],
      [10, Math.round(baseSilverGold * (1 + 0.19)), Math.round(baseSilverGold * (1 + 3.43))],
      [11, Math.round(baseSilverGold * (1 + 0.19)), Math.round(baseSilverGold * (1 + 3.53))],
    ];
    averages = [
      [1, Math.round(baseSilverGold * (1 + 0.24))],
      [2, Math.round(baseSilverGold * (1 + 0.36))],
      [3, Math.round(baseSilverGold * (1 + 0.50))],
      [4, Math.round(baseSilverGold * (1 + 0.50))],
      [5, Math.round(baseSilverGold * (1 + 0.63))],
      [6, Math.round(baseSilverGold * (1 + 0.74))],
      [7, Math.round(baseSilverGold * (1 + 0.68))],
      [8, Math.round(baseSilverGold * (1 + 1.05))],
      [9, Math.round(baseSilverGold * (1 + 1.21))],
      [10, Math.round(baseSilverGold * (1 + 1.13))],
      [11, Math.round(baseSilverGold * (1 + 1.32))],
    ];
  } else if (props.pageName === 'goldToPlat') {
    baseGoldPlatinum = props.userInput;
    ranges = [
      [1, Math.round(baseGoldPlatinum * (1 - 0.17)), Math.round(baseGoldPlatinum * (1 + 1.15))],
      [2, Math.round(baseGoldPlatinum * (1 + 0.24)), Math.round(baseGoldPlatinum * (1 + 1.15))],
      [3, Math.round(baseGoldPlatinum * (1 + 0.13)), Math.round(baseGoldPlatinum * (1 + 1.38))],
      [4, Math.round(baseGoldPlatinum * (1 + 0.38)), Math.round(baseGoldPlatinum * (1 + 1.18))],
      [5, Math.round(baseGoldPlatinum * (1 + 0.19)), Math.round(baseGoldPlatinum * (1 + 2.41))],
      [6, Math.round(baseGoldPlatinum * (1 + 0.43)), Math.round(baseGoldPlatinum * (1 + 4.18))],
      [7, Math.round(baseGoldPlatinum * (1 + 0.29)), Math.round(baseGoldPlatinum * (1 + 3.95))],
      [8, Math.round(baseGoldPlatinum * (1 + 0.45)), Math.round(baseGoldPlatinum * (1 + 3.23))],
    ];
    averages = [
      [1, Math.round(baseGoldPlatinum * (1 + 0.41))],
      [2, Math.round(baseGoldPlatinum * (1 + 0.52))],
      [3, Math.round(baseGoldPlatinum * (1 + 0.69))],
      [4, Math.round(baseGoldPlatinum * (1 + 0.65))],
      [5, Math.round(baseGoldPlatinum * (1 + 0.90))],
      [6, Math.round(baseGoldPlatinum * (1 + 1.71))],
      [7, Math.round(baseGoldPlatinum * (1 + 1.57))],
      [8, Math.round(baseGoldPlatinum * (1 + 1.71))],
    ];
  }

  const options = {
    chart: {
      height: '641px',
      borderRadius: '25px',
      type: 'line',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: 'Projections',
      backgroundColor: '#000',
      // align: 'left',
    },
    xAxis: {
      title: {
        text: 'Month',
        style: {
          color: '#858585',
          fontSize: '16px',
          fontWeight: '600',
          fontFamily: 'Roboto, sans-serif',
          textAlign: 'left',
        },
      },
      labels: {
        style: {
          fontStyle: 'italic',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Increases in New Patient Calls',
        style: {
          color: '#858585',
          fontSize: '16px',
          fontWeight: '600',
          fontFamily: 'Roboto, sans-serif',
        },
      },
      labels: {
        style: {
          fontStyle: 'italic',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [{
      name: 'Averages',
      showInLegend: false,
      data: averages,
      zIndex: 1,
      color: '#0DCB38',
      lineWidth: 2,
      dataLabels: {
        enabled: true,
        textOutline: 'none',
        color: '#0DCB38',
      },
      marker: {
        fillColor: '#0DCB38',
        lineWidth: 2,
        lineColor: '#fff',
      },
    }, {
      name: 'Range',
      data: ranges,
      type: 'arearange',
      lineWidth: 0,
      linkedTo: ':previous',
      color: '#024EA0',
      fillOpacity: 1,
      zIndex: 0,
      dataLabels: {
        enabled: true,
        textOutline: 'none',
        color: '#0969C1',
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: '#2E94FF',
        lineWidth: 2,
        fillColor: '#000',
      },
    }],
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
export default MyStockChart;
