import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
  // Routes,
  // Route,
  // Navigate,
} from 'react-router-dom';
import store from './context/store';
import App from './App';
import ErrorPage from './error-page';
import Home from './pages/home';
import WhyUpgrade from './pages/why-upgrade';
import ContentLinkingStrategy from './pages/content-linking-strategy';
import BaseProductBlogDifferences from './pages/base-product-blog-differences';
import SocialContentStrategy from './pages/social-content-strategy';
import ContentIgnitionPackage from './pages/content-ignition-package';
import WhatIsContentIgnitionPackage from './pages/what-is-content-ignition-package';
import HowDoIUseIt from './pages/how-do-i-use-it';
import HowManyDoIGet from './pages/how-many-do-i-get';
import BlogContentPackages from './pages/blog-content-packages';
import TotalBlogs from './pages/total-blogs';
import PatientExperiencePx from './pages/patient-experience-px';
import DashboardAndAppointmentReminder from './pages/dashboard-and-appointment-reminder';
import PatientReviewRequest from './pages/patient-review-request';
import Expectations from './pages/expectations';
import ExpectationSilverToGold from './pages/expectations-silver-to-gold';
import ExpectationGoldToPlatinum from './pages/expectations-gold-to-platinum';
import GoldPackage from './pages/gold-package';
import GoldPriceOffer from './pages/gold-price-offer';
import PlatinumPackage from './pages/platinum-package';
import PlatinumPriceOffer from './pages/platinum-price-offer';
import SpecialAddOn from './pages/special-add-on';

// Uncomment this line and the component itsself <Counter /> below to see how we can create slices
// to add to the reducer to let us update state withing any page or component
// import Counter from './components/Counter';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/why-upgrade',
        element: <WhyUpgrade />,
      },
      {
        path: '/content-linking-strategy',
        element: <ContentLinkingStrategy />,
      },
      {
        path: '/base-product-blog-differences',
        element: <BaseProductBlogDifferences />,
      },
      {
        path: '/social-content-strategy',
        element: <SocialContentStrategy />,
      },
      {
        path: '/content-ignition-package',
        element: <ContentIgnitionPackage />,
      },
      {
        path: '/what-is-content-ignition-package',
        element: <WhatIsContentIgnitionPackage />,
      },
      {
        path: '/how-do-i-use-it',
        element: <HowDoIUseIt />,
      },
      {
        path: '/how-many-do-i-get',
        element: <HowManyDoIGet />,
      },
      {
        path: '/blog-content-packages',
        element: <BlogContentPackages />,
      },
      {
        path: '/total-blogs',
        element: <TotalBlogs />,
      },
      {
        path: '/patient-experience-px',
        element: <PatientExperiencePx />,
      },
      {
        path: '/dashboard-and-appointment-reminder',
        element: <DashboardAndAppointmentReminder />,
      },
      {
        path: '/patient-review-request',
        element: <PatientReviewRequest />,
      },
      {
        path: '/expectations',
        element: <Expectations />,
      },
      {
        path: '/expectations-silver-to-gold',
        element: <ExpectationSilverToGold />,
      },
      {
        path: '/expectations-gold-to-platinum',
        element: <ExpectationGoldToPlatinum />,
      },
      {
        path: '/gold-package',
        element: <GoldPackage />,
      },
      {
        path: '/gold-price-offer',
        element: <GoldPriceOffer />,
      },
      {
        path: '/platinum-package',
        element: <PlatinumPackage />,
      },
      {
        path: '/platinum-price-offer',
        element: <PlatinumPriceOffer />,
      },
      {
        path: '/special-add-on',
        element: <SpecialAddOn />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <Counter /> */}
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);
