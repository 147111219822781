import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TopNav from '../components/TopNav';
import MyStockChart from '../components/DynamicGraph';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';
import Image from '../images/expectations-silver-to-gold.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 860px;
  background-color: #002850;
  background-image: -webkit-linear-gradient(-30deg, #003263 80%, #1183F2 50%);
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
  }
`;

const MainContent = styled(Grid)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  max-width: 100%;
  box-shadow: 0 1px 30px -4px rgb(0 0 0 / 75%);
  border-radius: 15px;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
`;

const SilverToGold = styled.h4`
  font-size: 38px;
  text-align: left;
  font-weight: 500;
  padding: 0 15px 1rem 33px;
  margin: auto;
  @media only screen and (min-width: 1600px) {
    font-size: 43px;
  }
`;

const ParagraphContent = styled.div`
  font-size: 22px;
  font-weight: 100;
  padding: 0 5px;
  > p {
    padding: 10px 2em;
    margin: auto;
  }
  @media only screen and (min-width: 1800px) {
    padding: 0 1.5em;

  }
`;

const TextAndButtonGrid = styled(Grid)`
  fontSize: 14px;
  display: flex;
  margin-top: 1em;
  justify-content: center;
`;

const GreenButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0DCB38;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0 0px 0 15px;
  width: 200px;
`;

const Input = styled.input`
  width: 30%;
  height: 75px;
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem; 
  &:focus,
  &:active {
    border-color: #1f96fe;
    outline: none;
  }
`;

const ExpectationSilverToGold = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Expectation: Silver to Gold'));
    dispatch(setParentTo('Expectations'));
    document.title = 'Expectation: Silver to Gold - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  const [isContentVisible, setContentVisible] = useState(true);
  const [isContent2Visible, setContent2Visible] = useState(false);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setContent2Visible(!isContent2Visible);
  };

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <StyledMostParentDiv className="home-page">
      <TopNav />
      <MainContent container>
        <Grid lg={3}>
          <SilverToGold>Silver to Gold</SilverToGold>
          {isContentVisible && (
          <ParagraphContent>
            This graph shows the average increase in new patient calls
            for our clients after upgrading from Silver to Gold.
            This includes clients from all professions, locations and demographics.
          </ParagraphContent>
          )}
          {isContent2Visible && (
            <div>
              <ParagraphContent>
                Please enter the number of new patient calls you currently experience
                below. The graph will show your projected new patient call amount after
                upgrading from Silver to Gold.
              </ParagraphContent>
              <Box justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>
                <Input
                  type="number"
                  onChange={(e) => handleInputChange(e)}
                  value={inputValue}
                />
              </Box>
            </div>
          )}
        </Grid>
        <Grid lg={9} style={{ paddingRight: '20px', textAlign: 'center' }}>
          {isContentVisible && (
          <Img src={Image} alt="icon" />
          )}
          {isContent2Visible && (
          <MyStockChart
            userInput={inputValue}
            pageName="silverToGold"
          />
          )}
          <TextAndButtonGrid>
            {isContentVisible && (
            <p style={{ paddingRight: '2rem' }}>
              Click Projections to view a personalized forecast
              for how your own patient call volume can improve.
            </p>
            )}
            {isContent2Visible && (
            <p style={{ paddingRight: '2rem' }}>
              Click Expectations to view the average
              improvements experienced by our clients.
            </p>
            )}
            <GreenButton type="button" onClick={toggleContentVisibility}>
              {isContentVisible && (
              <p>VIEW PROJECTIONS</p>
              )}
              {isContent2Visible && (
                <p>VIEW EXPECTATIONS</p>
              )}
              <ArrowRightIcon />
            </GreenButton>
          </TextAndButtonGrid>
        </Grid>
      </MainContent>
    </StyledMostParentDiv>
  );
};

export default ExpectationSilverToGold;
