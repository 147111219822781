/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';

export const setCurrent = createSlice({
  name: 'currentPage',
  initialState: {
    value: '/',
    parent: '',
  },
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes.
    //   // Also, no return statement is required from these functions.
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    setPageTo: (state, action) => {
      state.value = action.payload;
    },
    setParentTo: (state, action) => {
      state.parent = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageTo } = setCurrent.actions;
export const { setParentTo } = setCurrent.actions;

export default setCurrent.reducer;
