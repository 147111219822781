import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import styled from '@emotion/styled';
import topNavUrl from '../context/topNavUrl';
/* eslint no-nested-ternary: "off" */

const LeftRightIconContainer = styled.div`
display: flex;
justify-content: flex-end;
height: 50px;
border-radius: 0 25px 0 0;
padding-right: 1rem;
padding-top: 5px;
align-items: center;
@media only screen and (max-width: 1024px) {
  border-radius: 0;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: initial;
}
`;

const StyledLeftIcon = styled(ArrowLeftIcon)`
width: 45px;
height: 45px;
cursor: pointer;
@media only screen and (max-width: 1024px) {
  color: #1183F2;
}
color: ${(props) => (props.darkblueone === props.currentpage
    ? '#002F5C'
    : props.darkbluetwo === props.currentpage
      ? '#002F5C'
      : 'white')};
`;

const StyledRightIcon = styled(ArrowRightIcon)`
width: 45px;
height: 45px;
cursor: pointer;
@media only screen and (max-width: 1024px) {
  color: #1183F2;
}
color: ${(props) => (props.darkblueone === props.currentpage
    ? '#002F5C'
    : props.darkbluetwo === props.currentpage
      ? '#002F5C'
      : 'white')};   
`;

const TopNav = () => {
  const location = useLocation();
  let currentPage = location.pathname;
  const [prevUrl, setPrevUrl] = useState('');
  const [nextUrl, setNextUrl] = useState('');
  const homePageSwitch = ['/home', '/'];

  // combined handlePrevUrl and handleNextUrl into one and then wrapped it into useEffect which runs
  // whenever currentPage changes to ensure prevUrl and nextUrl are set correctly
  // before listening to keyboard events
  useEffect(() => {
    const handlePrevNextUrls = () => {
      for (let i = 0; i < topNavUrl.length; i++) {
        if (currentPage === '/') {
          currentPage = '/home';
        }
        if (currentPage === topNavUrl[i]) {
          setPrevUrl(topNavUrl[i - 1]);
          setNextUrl(topNavUrl[i + 1]);
        }
      }
    };
    handlePrevNextUrls();
  }, [currentPage]);

  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    // e.preventDefault();
    switch (e.key) {
      case 'ArrowLeft':
        navigate(prevUrl);
        break;
      case 'ArrowRight':
        navigate(nextUrl);
        break;
      default:
        // Handle other keys if needed
        break;
    }
  };

  // This is the cleanup function that is returned by the useEffect.
  // It's executed when the component unmounts or when the values in the dependency array change.
  // In this case, it removes the event listener that was added earlier.
  // This is important to prevent memory leaks and ensure that the event listener is
  // properly removed when the component is no longer in use.
  // https://dev.to/marcostreng/how-to-really-remove-eventlisteners-in-react-3och
  // https://www.pluralsight.com/guides/how-to-cleanup-event-listeners-react
  // https://hackernoon.com/cleanup-functions-in-reacts-useeffect-hook-explained
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [prevUrl, nextUrl]);

  return (
    <LeftRightIconContainer
      currentpage={currentPage}
      darkblueone="/content-ignition-package"
      darkbluetwo="/how-many-do-i-get"
    >
      {!homePageSwitch.includes(currentPage) ? (
        <Link to={prevUrl}>
          <StyledLeftIcon
            currentpage={currentPage}
            darkblueone="/content-ignition-package"
            darkbluetwo="/how-many-do-i-get"
          />
        </Link>
      ) : null}
      {currentPage !== '/special-add-on' ? (
        <Link to={nextUrl}>
          <StyledRightIcon
            currentpage={currentPage}
            darkblueone="/content-ignition-package"
            darkbluetwo="/how-many-do-i-get"
          />
        </Link>
      ) : null}
    </LeftRightIconContainer>
  );
};

export default TopNav;
