import React, { useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TopNav from '../components/TopNav';
import { setPageTo, setParentTo } from '../slices/setCurrentPage';

import Image from '../images/total-blogs.png';

const StyledMostParentDiv = styled.div`
  text-decoration: none;
  color: #fff;
  height: 860px;
  background-color: #002850;
  background-image: -webkit-linear-gradient(-30deg, #003263 80%, #1183F2 50%);
  border-radius: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  @media only screen and (max-width: 1024px) {
    height: 100%;
    border-radius: 0;
  }
`;

const Img = styled.img`
  max-width: 100%;
  margin: 1rem 0 2rem 3rem;
  box-shadow: 0 1px 1px -4px lightblue;
  @media only screen and (max-width: 1750px) {
    margin-left: 0;
    width: 100%;
  }
  // @media only screen and (max-width: 1440px) {
  //   max-width: 450px;
  //   margin-left: 3vw;
  // }
  // @media only screen and (max-width: 1024px) {
  //   max-width: 350px;
  // }
`;

const H1 = styled.h1`
  font-size: 52px;
  text-align: center;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1280px) {
    font-size: 45px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 20px;
  }
`;

const VerticalText = styled.h1`
  writing-mode: vertical-lr;  
  transform: rotate(180deg);
  text-orientation: use-glyph-orientation;
  font-size: 31px;
  text-align: center;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  letter-spacing: calc(-1rem * 0.001 * 20);
  @media only screen and (max-width: 1280px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

const TotalBlogs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTo('Total Blogs'));
    dispatch(setParentTo('Blog Content Packages'));
    document.title = 'Total Blogs - Intelligent Solutions for Practice Growth | Doctor Genius';
  }, []);
  return (
    <StyledMostParentDiv className="home-page">
      <TopNav />
      <Grid container display="flex" style={{ height: '100%' }} justifyContent="center" alignItems="center">
        <Grid display="flex" flexDirection="column" xs={1} sx={{ pl: 4 }}>
          <VerticalText>total blogs posted to your site</VerticalText>
        </Grid>
        <Grid display="flex" flexDirection="column" xs={10}>
          <H1 sx={{ pb: 1 }}>Enhanced Content Over Time</H1>
          <Img src={Image} alt="icon" />
        </Grid>
      </Grid>
    </StyledMostParentDiv>
  );
};

export default TotalBlogs;
